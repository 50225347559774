import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: 'login'
  },
  {
    name: 'privacyPolicy',
    component: () => import('../view/login/PrivacyPolicy'),
    meta: {
      title: '登录页面隐私协议'
    }
  },
  {
    name: 'applyLeaveAudit',
    component: () => import('../view/fitmentAudit/leaveAudit/appleForLeaveApproval'),
    meta: {
      title: '请假审核'
    }
  },
  {
    name: 'AboutHimi',
    component: () => import('../view/mine/AboutHimi'),
    meta: {
      title: '关于海米'
    }
  },
  // {
  //   name: 'inform',
  //   component: () => import('../view/mine/inform/inform'),
  //   meta: {
  //     title: '通知'
  //   }
  // },
  // {
  //   name: 'informDetails',
  //   component: () => import('../view/mine/inform/informDetails'),
  //   meta: {
  //     title: '通知详情'
  //   }
  // },
  {
    name: 'resetTariff',
    component: () => import('../view/mine/smartDevice/resetTariff'),
    meta: {
      title: '充值电费'
    }
  },
  {
    name: 'LeaveReviewDetails',
    component: () => import('../view/fitmentAudit/leaveAudit/LeaveReviewDetails'),
    meta: {
      title: '请假审核详情'
    }
  },
  {
    name: 'applyLeave',
    component: () => import('../view/fitmentAudit/leaveAudit/applyLeave'),
    meta: {
      title: '请假审核审批'
    }
  },
  {
    name: 'ownerEntrust',
    component: () => import('../view/mine/OwnerEntrust/OwnerEntrust'),
    meta: {
      title: '业主委托'
    }
  },
    //H5密码登录使用
  {
    name: 'userService',
    component: () => import('../view/login/UserServiceAgreement'),
    meta: {
      title: '登录页面用户服务协议'
    }
  },

  {
    name: 'TemporaryButton',
    component: () => import('../view/mine/TemporaryButton'),
    meta: {
      title: '按钮测试'
    }
  },
  {
    name: 'addFollowUp',
    component: () => import('../view/potentialHouse/followUp/addFollowUp'),
    meta: {
      title: '更多跟进'
    }
  },
  {
    name: 'addPropertyBill',
    component: () => import('../view/contract/PropertyBill/AddPropertyBill'),
    meta: {
      title: '添加物业交割单'
    }
  },
  {
    name: 'propertyDelivery',
    component: () => import('../view/contract/PropertyBill/propertyDelivery'),
    meta: {
      title: '物业交割单'
    }
  },
  {
    name: 'changeLease',
    component: () => import('../view/mine/MyContract/ChangeLease'),
    meta: {
      title: '变更租约',
      keepAlive: true
    }
  },
    /*我的合同变更租约申请使用*/
  {
    name: 'changeLeaseApply',
    component: () => import('../view/mine/MyContract/myChangeLeaseApply'),
    meta: {
      title: '我的合同变更租约',
      keepAlive: true
    }
  },
  {
    name: 'viewDetails',
    component: () => import('../view/afterRent/maintain/ViewDetails'),
    meta: {
      title: '查看详情'
    }
  },
  {
    name: 'myContract',
    component: () => import('../view/mine/MyContract/MyContract'),
    meta: {
      title: '我的合同',
      keepAlive: true // 需要缓存
    }
  },
  {
    name: 'keepCleanInfo',
    component: () => import('../view/afterRent/cleanKeep/KeepCleanInfo'),
    meta: {
      title: '保洁详情'
    }
  },
  // {
  //   name: 'addProperty',
  //   component: () => import('../view/AddPropertyBill'),
  //   meta: {
  //     title: '添加物业交割单'
  //   }
  // },
  {
    name: 'user',
    component: () => import('../view/user/index'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'cart',
    component: () => import('../view/cart/index'),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'goods',
    component: () => import('../view/goods/index'),
    meta: {
      title: '商品详情'
    }
  },
  {
    name: 'applyHouseDecoration',
    component: () => import('../view/potentialHouse/practicalExploration/applyHouseDecoration'),
    meta: {
      title: '装修申请'
    }
  },
  {
    name: 'applyCheckOut',
    component: () => import('../view/potentialHouse/practicalExploration/applyCheckOut'),
    meta: {
      title: '业主退房'
    }
  },
  {
    name: 'bindBankCardChange',
    component: () => import('../view/potentialHouse/practicalExploration/bindBankCardChange'),
    meta: {
      title: '修改银行卡'
    }
  },
  {
    name: 'bindBankCard',
    component: () => import('../view/potentialHouse/practicalExploration/bindBankCard'),
    meta: {
      title: '绑定银行卡'
    }
  },
  {
    name: 'practicalExplorationInfo',
    component: () => import('../view/potentialHouse/practicalExploration/practicalExplorationInfo'),
    meta: {
      title: '实勘信息',
    }
  },
  {
    name:'PotentialHouseList',
    component: () => import('../view/potentialHouse/potentialHouseList/PotentialHouse'),
    meta: {
      title: '潜房列表',

    }
  },
  {
    name: 'addPotentialHouse',
    component: () => import('../view/potentialHouse/addPotentialHouse/addPotentialHouse'),
    meta: {
      title: '我的潜房详情',
      // keepAlive : true
    }
  },
  {
    name:'decorationSchemeAudit',
    component: () => import('../view/fitmentAudit/ReviewOfDecorationScheme'),
    meta: {
      title: '装修方案审核'
    }
  },
  {
    name: 'RegisterPotentialHouse',
    component: () => import('../view/potentialHouse/RegisterPotentialHouse/RegisterPotentialHouse'),
    meta: {
      title: '添加潜房',
      keepAlive : true
    }
  },
  {
    name: 'IHaveComplaint',
    component: () => import('../view/mine/IHaveComplaint/IHaveComplaint'),
    meta: {
      title: '我要投诉',
      keepAlive : true
    }
  },

  {
    name: 'ApplicationForRehousing',
    component: () => import('../view/potentialHouse/ApplicationForRehousing/ApplicationForRehousing'),
    meta: {
      title: '收房申请'
    }
  },
  {
    name: 'applyHouseCollection',
    component: () => import('../view/potentialHouse/practicalExploration/applyHouseCollection'),
    meta: {
      title: '收房申请审核'
    }
  },
  {
    name: 'AddPracticalSurvey',
    component: () => import('../view/potentialHouse/AddPracticalSurvey/AddPracticalSurvey'),
    meta: {
      title: '添加实勘'
    }
  },
  {
    name: 'RecommendResume',
    component: () => import('../view/mine/RecommendResume/RecommendResume'),
    meta: {
      title: '推荐简历'
    }
  },
  {
    name: 'contractChoose',
    component: () => import('../view/mine/contractChoose/contractChoose'),
    meta: {
      title: '选择房源'
    }
  },
  {
    name: 'SelectRoom',
    component: () => import('../view/afterRent/Complaint/SelectRoom'),
    meta: {
      title: '选择房源'
    }
  },
  {
    name: 'SelectDepartment',
    component: () => import('../view/afterRent/Complaint/SelectDepartment'),
    meta: {
      title: '选择部门'
    }
  },
  {
    name: 'RealNameAuthentication',
    component: () => import('../view/mine/RealNameAuthentication/RealNameAuthentication'),
    meta: {
      title: '实名认证'
    }
  },
  {
    name: 'realNameAuthenticationed',
    component: () => import('../view/mine/RealNameAuthentication/realNameAuthenticationed'),
    meta: {
      title: '已认证'
    }
  },
  {
    name: 'UploadIDCard',
    component: () => import('../view/mine/RealNameAuthentication/UploadIDCard'),
    meta: {
      title: '上传信息'
    }
  },
  {
    name: 'NewContract',
    component: () => import('../view/contract/NewContract/NewContract'),
    meta: {
      title: '新增合同',
      keepAlive:true
    }
  },
  {
    name: 'selectContractRoomList',
    component: () => import('../view/contract/NewContract/selectContractRoomList'),
    meta: {
      title: '租客合同房源选择列表',
    }
  },
  {
    name: 'selectRenterMobileList',
    component: () => import('../view/contract/NewContract/selectRenterMobileList'),
    meta: {
      title: '租客电话号码列表',
    }
  },
  {
    name: 'newOwnerContract',
    component: () => import('../view/contract/NewContract/newOwnerContract'),
    meta: {
      title: '新增业主合同',
      keepAlive:true
    }
  },
  {
    name: 'ComplaintCompleted',
    component: () => import('../view/afterRent/Complaint/ComplaintCompleted'),
    meta: {
      title: '投诉完成'
    }
  },
  {
    name: 'ComplaintVerification',
    component: () => import('../view/afterRent/Complaint/ComplaintVerification'),
    meta: {
      title: '投诉核验'
    }
  },
  {
    name: 'RepairDetails',
    component: () => import('../view/afterRent/maintain/RepairDetails'),
    meta: {
      title: '维修完成'
    }
  },
  {
    name: 'BroadbandCompletion',
    component: () => import('../view/afterRent/Broadband/BroadbandCompletion'),
    meta: {
      title: '宽带完成'
    }
  },
  {
    name:'decorationFinalAudit',
    component: () => import('../view/fitmentAudit/finalApplication'),
    meta: {
      title: '装修决算申请'
    }
  },
  {
    name:'decorationCheckAudit',
    component: () => import('../view/fitmentAudit/checkApplication'),
    meta: {
      title: '装修验收申请'
    }
  },
  /*收房申请审核列表*/
  {
    name: 'reviewOfApplyForHouse',
    component: () => import('../view/potentialHouse/reviewOfApplyForHouse/reviewOfApplyForHouse'),
    meta: {
      title: '收房申请审核'
    }
  },
    //流程审批 租约变更列表
  {
    name: 'applyForChangeLease',
    component: () => import('../view/applyForChangeLease/applyForChangeLease/applyForChangeLease'),
    meta: {
      title: '租约变更申请'
    }
  },
  {
    name: 'workbench',
    component: () => import('../view/workbench/workbench'),
    meta: {
      title: '工作台'
    }
  },
    //流程审批 装修申请审核
  {
    name: 'decorationApplyAudit',
    component: () => import('../view/fitmentAudit/decorationAudit'),
    meta: {
      title: '装修申请审核'
    }
  },
  {
    name:'login',
    component: () => import('../view/login/login'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'codeLogin',
    component: () => import('../view/login/codeLogin'),
    meta: {
      title: '验证码登录'
    }
  },
  {
    name: 'bindPhone',
    component: () => import('../view/login/bindPhone'),
    meta: {
      title: '手机绑定'
    }

  },
  {
    name: 'fitmentAuditViewDetail',
    component: () => import('../view/fitmentAudit/viewDetail'),
    meta: {
      title: '查看详情'
    }
  },
  {
    name: 'applicationForCollection',
    component: () => import('../view/potentialHouse/applicationForCollection/applicationForCollection'),
    meta: {
      title: '收房申请详情'
    }
  },
  {
    name: 'SetPersonalInfo',
    component: () => import('../view/mine/SetPersonalInfo'),
    meta: {
      title: '设置'
    }
  },
  {
    name: 'inviteFriends',
    component: () => import('../view/user/InviteFriends'),
    meta: {
      title: '推荐有礼'
    }
  },
  {
    name: 'getDoorPassword',
    component: () => import('../components/rongxun/rx-doorPassword/GetDoorPassword'),
    meta: {
      title: '获取公门房门密码'
    }
  },
  {
    name: 'broadBandDetails',
    component: () => import('../view/afterRent/Broadband/broadBandDetails'),
    meta: {
      title: '宽带安装中查看详情'
    }
  },
  {
    name: 'getLockPassword',
    component: () => import('../components/rongxun/rx-doorPassword/GetLockPassword'),
    meta: {
      title: '获取门锁密码'
    }
  },
  {
    name: 'dialMobile',
    component: () => import('../components/rongxun/rx-dialMobile/dialMobile'),
    meta: {
      title: '拨打电话'
    }
  },
    //租后装修列表
  {
    name: 'repairPending',
    component: () => import('../view/fitmentAudit/repair/repairPending'),
    meta: {
      title: '装修列表'
    }
  },
  {
    name: 'houseDetails',
    component: () => import('../view/houseResources/houseDetails/houseDetails'),
    meta: {
      title: '房屋详情'
    }
  },
  {
    name: 'contractList',
    component: () => import('../view/contract/contractList/contractList'),
    meta: {
      title: '合同列表',
    }
  },
  {
    name: 'contractDetail',
    component: () => import('../view/contract/contractDetail'),
    meta: {
      title: '合同详情',
    },
  },
  {
    name: 'perContractDetail',
    component: () => import('../view/contract/perContractDetail'),
    meta: {
      title: '预定合同详情',
    },
  },
  {
    name: 'electronicContract',
    component: () => import('../view/contract/electronicContract'),
    meta: {
      title: '电子合同'
    }
  },
  {
    name: 'phoneLogin',
    component: () => import('../view/login/phoneLogin'),
    meta: {
      title: '手机登录'
    }
  },
  {
    name: 'setPassword',
    component: () => import('../view/login/setPassword'),
    meta: {
      title: '设置密码'
    }
  },
  {
    name: 'alterPassword',
    component: () => import('../view/login/alterPassword'),
    meta: {
      title: '修改密码'
    }
  },
    {
        name: 'forgotPassword',
        component: () => import('../view/login/forgotPassword'),
        meta: {
            title: '忘记密码'
        }
    },
  {
    name: 'test',
    component: () => import('../view/login/test'),
    meta: {
      title: 'test'
    }
  },
  {
    name: 'PotentialClient',
    component: () => import('../view/PotentialClient/PotentialClient'),
    meta: {
      title: '潜客'
    }
  },
  {
    name: 'potentialClientDetails',
    component: () => import('../view/PotentialClient/potentialClientDetails'),
    meta: {
      title: '潜客详情',
      keepAlive:true
    }
  },
  {
    name: 'roomChoose',
    component: () => import('../view/PotentialClient/roomChoose'),
    meta: {
      title: '预定房间选择'
    }
  },
  {
    name: 'addPotentialClient',
    component: () => import('../view/PotentialClient/addPotentialClient'),
    meta: {
      title: '添加潜客',
      keepAlive:true
    }
  },
  {
    name: 'seekHouseCollection',
    component: () => import('../view/potentialHouse/practicalExploration/seekHouseCollection'),
    meta: {
      title: '寻找房源'
    }
  },
  {
    name: 'referrer',
    component: () => import('../view/potentialHouse/addPotentialHouse/referrer'),
    meta: {
      title: '推荐人'
    }
  },
  {
    name: 'chooseCommunity',
    component: () => import('../view/potentialHouse/addPotentialHouse/chooseCommunity'),
    meta: {
      title: '选择小区'
    }
  },
  {
    name: 'takeALook',
    component: () => import('../view/takeALook/takeALook'),
    meta: {
      title: '带看'
    }
  },
  {
    name: 'alterPhone',
    component: () => import('../view/login/alterPhone'),
    meta: {
      title: '更换手机号'
    }
  },
  {
    name: 'addTakeALook',
    component: () => import('../view/takeALook/userInfo'),
    meta: {
      title: '新增带看'
    }
  },
  {
    name: 'checkTakeALook',
    component: () => import('../view/takeALook/takeALookResult'),
    meta: {
      title: '确认带看'
    }
  },
  {
    name: 'complaintAcceptance',
    component: () => import('../view/workbench/complaintAcceptance'),
    meta: {
      title: '投诉受理',
      keepAlive : true
    }
  },
  {
    name: 'statisticsModule',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '统计模块'
    }
  },
  /*用户画像*/
  {
      name: 'userPortrayal',
      component: () => import('../view/dataStatistics/statisticsModule'),
      meta: {
          title: '用户画像'
      }
  },
  /*装修统计*/
  {
    name: 'decorationStatistics',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '装修统计'
    }
  },
  /*业务统计*/
  {
    name: 'businessStatistics',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '业务统计'
    }
  },
  /*客户服务*/
  {
    name: 'customerService',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '客户服务'
    }
  },
  /*租后统计*/
  {
    name: 'afterRentStatistics',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '租后统计'
    }
  },
  /*财务模块*/
  {
    name: 'financialModule',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '财务模块'
    }
  },
  /*业绩模块*/
  {
    name: 'performanceStatement',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '业绩模块'
    }
  },
  /*基础人事*/
  {
    name: 'basepersonnel',
    component: () => import('../view/dataStatistics/statisticsModule'),
    meta: {
      title: '基础人事'
    }
  },
    /*集寓分析*/
    {
        name: 'setApartmentStatement',
        component: () => import('../view/dataStatistics/statisticsModule'),
        meta: {
            title: '集寓分析'
        }
    },
  {
    name: 'distributionDetails',
    component: () => import('../view/distribution/distributionAudit'),
    meta: {
      title: '配货审核',
    }
  },
  {
    name: 'preContract',
    component: () => import('../view/contract/reserveContract/preContract'),
    meta: {
      title: '预定合同'
    }
  },
  {
    name: 'newReserve',
    component: () => import('../view/contract/newReserve/newReserve'),
    meta: {
      title: '新增预定',
      keepAlive:true

    }
  },
  {
    name: 'ComplaintViewDetails',
    component: () => import('../view/afterRent/ComplaintCompleted/ComplaintViewDetails'),
    meta: {
      title: '投诉查看详情'
    }
  },
  {
    name: 'acceptanceCheckTheDetails',
    component: () => import('../view/afterRent/maintain/acceptanceCheckTheDetails'),
    meta: {
      title: '维修查看详情'
    }
  },
  {
    name: 'decoratePlan',
    component: () => import('../view/decoration/decoratePlan'),
    meta: {
      title: '装配方案'
    }
  },
  {
    name: 'myOrder',
    component: () => import('../view/mine/myOrder/myOrder'),
    meta: {
      title: '我的预约'
    }
  },
  {
    name: 'broadBandDetails',
    component: () => import('../view/afterRent/Broadband/broadBandDetails'),
    meta: {
      title: '宽带安装中查看详情'
    }
  },
  {
    name: 'cleanKeepDetails',
    component: () => import('../view/afterRent/cleanKeep/cleanKeepDetails'),
    meta: {
      title: '保洁细节'
    }
  },
  {
    name: 'myOrder',
    component: () => import('../view/mine/myOrder/myOrder'),
    meta: {
      title: '我的预约'
    }
  },
    //租后维修列表
  {
    name: 'repairToBeAccepted',
    component: () => import('../view/afterRent/maintain/repairToBeAccepted'),
    meta: {
      title: '维修列表'
    }
  },
  {
    name: 'broadbandInstall',
    component: () => import('../view/afterRent/Broadband/broadbandInstall'),
    meta: {
      title: '宽带安装列表'
    }
  },
    //租后保洁列表
  {
    name: 'cleanAndToDo',
    component: () => import('../view/afterRent/cleanKeep/cleanAndToDo'),
    meta: {
      title: '保洁列表'
    }
  },
  {
    name: 'iWantCleaning',
    component: () => import('../view/mine/iWantCleaning/iWantCleaning'),
    meta: {
      title: '我要保洁'
    }
  },
  {
    name: 'iWantRepair',
    component: () => import('../view/mine/iWantRepair/iWantRepair'),
    meta: {
      title: '我要维修'
    }
  },
    //租后配货列表
  {
    name: 'prepareGoods',
    component: () => import('../view/mine/prepareGoods/prepareGoods'),
    meta: {
      title: '配货'
    }
  },
    {
        name: 'prepareKeepInfo',
        component: () => import('../view/mine/prepareGoods/prepareKeepInfo'),
        meta: {
            title: '配货完成'
        }
    },
  {
    name: 'myRepair',
    component: () => import('../view/mine/myRepair/myRepair'),
    meta: {
      title: '我的维修'
    }
  },
  {
    name: 'myComplaint',
    component: () => import('../view/mine/myComplaint/myComplaint'),
    meta: {
      title: '我的投诉'
    }
  },
  {
    name: 'mySmartDevice',
    component: () => import('../view/mine/smartDevice/mySmartDevice'),
    meta: {
      title: '我的智能设备'
    }
  },
  {
    name: 'doorLock',
    component: () => import('../view/mine/smartDevice/doorLock'),
    meta: {
      title: '智能门锁'
    }
  },
  {
    name: 'electricmeter',
    component: () => import('../view/mine/smartDevice/electricmeter'),
    meta: {
      title: '智能电表'
    }
  },
  {
    name: 'addAppointment',
    component: () => import('../view/houseResources/addAppointment'),
    meta: {
      title: '新增预约'
    }
  },
  {
    name: 'houseRelevant',
    component: () => import('../view/houseRelevant/houseRelevant'),
    meta: {
      title: '房源相关'
    }
  },
  {
    name: 'changeQuotation',
    component: () => import('../view/houseRelevant/changeQuotation'),
    meta: {
      title: '修改报价'
    }
  },
  {
    name: 'changeCostPrice',
    component: () => import('../view/houseRelevant/changeCostPrice'),
    meta: {
      title: '负成本报价'
    }
  },  
  {
    name: 'AddHouseRelecantPhoto',
    component: () => import('../view/houseRelevant/AddHouseRelecantPhoto'),
    meta: {
      title: '添加房源相关图片'
    }
  },
  {
    name: 'roomConfiguration',
    component: () => import('../view/houseRelevant/roomConfiguration'),
    meta: {
      title: '房间配置'
    }
  },  
  {
    name: 'distributionViewDetails',
    component: () => import('../view/distribution/viewDetails'),
    meta: {
      title: '配货查看详情'
    }
  },
  {
    name: 'results',
    component: () => import('../view/mine/results/results'),
    meta: {
      title: '业绩'
    }
  },
    //租后投诉列表
  {
    name: 'maintenancePendingTreatment',
    component: () => import('../view/afterRent/Complaint/maintenancePendingTreatment'),
    meta: {
      title: '投诉列表'
    }
  },
  {
    name: 'myWallet',
    component: () => import('../view/mine/wallet/myWallet'),
    meta: {
      title: '零钱明细'
    }
  },
  {
    name: 'applyWithdrawal',
    component: () => import('../view/mine/wallet/applyWithdrawal'),
    meta: {
      title: '申请提现'
    }
  },
  {
    name: 'wallet',
    component: () => import('../view/mine/wallet/wallet'),
    meta: {
      title: '钱包'
    }
  },
  {
    name: 'withdrawalPlan',
    component: () => import('../view/mine/wallet/withdrawalPlan'),
    meta: {
      title: '提现进度'
    }
  },
  {
    name: 'myCollection',
    component: () => import('../view/mine/collection/myCollection'),
    meta: {
      title: '我的收藏'
    }
  },
  {
    name: 'doceration',
    component: () => import('../view/afterRent/doceration/doceration'),
    meta: {
      title: '装修质量验收'
    }
  },
  {
    name: 'applyLeave',
    component: () => import('../view/fitmentAudit/leaveAudit/applyLeave'),
    meta: {
      title: '请假申请'
    }
  },
  {
    name: 'myCleaning',
    component: () => import('../view/mine/myCleaning/myCleaning'),
    meta: {
      title: '我的保洁'
    }
  },
  {
    name: 'ownerQuit',
    component: () => import('../view/fitmentAudit/ownerSurrenderAudit/ownerQuit'),
    meta: {
      title: '业主退租'
    },
  },
  {
    name: 'ownerQuitDetails',
    component: () => import('../view/fitmentAudit/ownerSurrenderAudit/ownerQuitDetails'),
    meta: {
      title: '业主退租详情'
    },
  },
  {
    name: 'chooseCity',
    component: () => import('../view/workbench/chooseCity'),
    meta: {
      title: '选择城市'
    },
  },
    //寻找房源//首页、寻找房源使用
  {
    name: 'lookForHouse',
    component: () => import('../view/workbench/lookForHouse'),
    meta: {
      title: '寻找房源'
    },
  },
  //装修列表更新进度
  {
    name: 'updateProgress',
    component: () => import('../view/decoration/updateProgress'),
    meta: {
      title: '更新进度'
    },
  },
  {
    name: 'allocationAudit',
    component: () => import('../view/fitmentAudit/allocationAudit'),
    meta: {
      title: '配货申请审核列表',
      keepAlive: true // 需要缓存
    },
  },
  {
    name: 'myChangeLease',
    component: () => import('../view/mine/leaseChange/leaseChange'),
    meta: {
      title: '租约变更'
    },
  },
  {
    name: 'changeDetails',
    component: () => import('../view/mine/changeDetails/changeDetails'),
    meta: {
      title: '变更详情'
    },
  },
  {
    name: 'integral',
    component: () => import('../view/mine/integral/integral'),
    meta: {
      title: '我的积分'
    },
  },
  {
    name: 'myBill',
    component: () => import('../view/mine/bill/bill'),
    meta: {
      title: '账单'
    },
  },
  {
    name: 'myDiscountCoupon',
    component: () => import('../view/mine/coupon/coupon'),
    meta: {
      title: '优惠券'
    },
  },
  {
    name: 'DiscountsTicket',
    component: () => import('../view/mine/coupon/DiscountsTicket'),
    meta: {
      title: '选择优惠券'
    },
  },
  {
    name: 'wage',
    component: () => import('../view/mine/wage/wage'),
    meta: {
      title: '工资'
    },
  },
  {
    name: 'wageDetail',
    component: () => import('../view/mine/wageDetail/wageDetail'),
    meta: {
      title: '工资明细'
    },
  },
  {
    name: 'wageHistoryDetail',
    component: () => import('../view/mine/wageDetail/wageHistoryDetail'),
    meta: {
      title: '补扣项明细'
    },
  },
  //首次业绩详情
  {
    name: 'firstResultsDetail',
    component: () => import('../view/mine/results/firstResultsDetail'),
    meta: {
      title: '业绩详情'
    },
  },
  //二次业绩详情
  {
    name: 'secondResultsDetail',
    component: () => import('../view/mine/results/secondResultsDetail'),
    meta: {
      title: '二次业绩详情'
    },
  },
  //中退业绩详情
  {
    name: 'middleResultsDetail',
    component: () => import('../view/mine/results/middleResultsDetail'),
    meta: {
      title: '中退业绩详情'
    },
  },
  //租后扣减业绩详情
  {
    name: 'afterRentResultsDetail',
    component: () => import('../view/mine/results/afterRentResultsDetail'),
    meta: {
      title: '租后扣减业绩详情'
    },
  },
  //空置业绩详情
  {
    name: 'vacantResultsDetail',
    component: () => import('../view/mine/results/vacantResultsDetail'),
    meta: {
      title: '空置业绩详情'
    },
  },
  //对冲业绩详情
  {
    name: 'hedgingResultsDetail',
    component: () => import('../view/mine/results/hedgingResultsDetail'),
    meta: {
      title: '对冲业绩详情'
    },
  },
  {
    name: 'leave',
    component: () => import('../view/mine/leave/leave'),
    meta: {
      title: '请假'
    },
  },
  {
    name: 'iwleave',
    component: () => import('../view/mine/leave/iwleave'),
    meta: {
      title: '我要请假'
    },
  },
  {
    name: 'upgrade',
    component: () => import('../view/upgrade/upgrade'),
    meta: {
      title: '升级'
    },
  },
  {
    name: 'addClearRetreatPhoto',
    component: () => import('../view/clearRetreat/addClearRetreatPhoto'),
    meta: {
      title: '添加清退图片'
    },
  },
  {
    name: 'clearRetreat',
    component: () => import('../view/clearRetreat/clearRetreat'),
    meta: {
      title: '清退'
    },
  },
  {
    name: 'unPaid',
    component: () => import('../view/paymentApproval/paymentApproval'),
    meta: {
      title: '待审批'
    },
  },


];
// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export {
  router
};
